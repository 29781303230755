import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SaveScoreForm({ correctCount, maxQuestion }) {

    

    return (
        <div className="container">
            { ( correctCount/maxQuestion === 1) && ( <h1>MEGA-Star!</h1> )}
            { ( correctCount/maxQuestion >= 0.8) && ( <h1>Super!</h1> )}
            { ( correctCount/maxQuestion < 0.8) && ( <h1>Naja!</h1> )}
            <h1>Richtig: {correctCount}</h1>
            <Link to="/" className="btn">
                Zurück
            </Link>
        </div>
    );
}
