import React, { StrictMode } from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase from './components/firebase/firebase';
import { FirebaseContext } from './components/firebase/firebaseContext';

//ReactDOM.render();
const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
    <FirebaseContext.Provider value={new Firebase()}>
            <App />
    </FirebaseContext.Provider>
        );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();