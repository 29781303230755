import React from 'react';
import './App.css';
import Home from './components/home';
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import Game from './components/game';

function App() {
    return (
        <Router>
            <div className="container">
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/game/:continent" element={<Game />} />
                
            </Routes>
            </div>
        </Router>
    ); 
}

export default App;
