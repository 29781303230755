import React, { useState } from 'react';

export default function Question({ question, changeQuestion }) {
    const [classToApply, setClassToApply] = useState('');
    const [showCorrectChoice, setShowCorrectChoice] = useState('');
    const [selectedAnswer, setSelectedAnswer] = useState(-1);
    const [answering, setAnswering] = useState(false);

    const checkAnswer = (selectedAnswer) => {
        if (answering) return;

        setAnswering(true);
        setSelectedAnswer(selectedAnswer);

        const classToApply = selectedAnswer === question.answer ? 'correct' : 'incorrect';
        setClassToApply(classToApply);

        const showCorrectChoice = classToApply === 'incorrect' ? 'correct' : 'incorrect';
        setShowCorrectChoice(showCorrectChoice);

        
        const wrong = selectedAnswer === question.answer ? 0 : 1;

        setTimeout(() => {
            setSelectedAnswer(-1);
            setAnswering(false);
            changeQuestion(wrong);
        }, 1000);
    };


    return (
        <div>
            <h2>Was ist die Hauptstadt von <br/>
                <strong>{question.country}</strong>?</h2>
            {question.answerChoices.map((choice, index) => (
                <div
                    key={index}
                    className={`choice-container 
                    ${ selectedAnswer === index && classToApply } 
                    ${ selectedAnswer !== question.answer && selectedAnswer >= 0 && question.answer === index && showCorrectChoice }`}
                    onClick={() => checkAnswer(index)}
                >
                    <p className="choice-prefix">{index + 1}</p>
                    <p className="choice-text" dangerouslySetInnerHTML={{ __html: choice.text }}
                    ></p>
                </div>
            ))}
        </div>
    );
}
