import React from 'react';
import ProgressBar from './progressBar';

export default function HUD({ questionCount, maxQuestion }) {
    return (
        <div id="hud">
            <div className="hud-item">
                <p className="hud-prefix">Frage</p>
                <h1 className="hud-main-text">{questionCount}/{maxQuestion}</h1>
            </div>
        </div>
    );
}
