import React, {useState} from 'react';
import Question from './question';
import countries from '../data/countries.json'
import HUD from "./hud";
import SaveScoreForm from "./saveScoreForm";
import {Link, useNavigate, useParams} from 'react-router-dom';

const dummyQuestion = {
    country: "Österreich",
    answerChoices: [
        { text: 'Linz' },
        { text: 'Wien' },
        { text: 'test' },
        { text: 'Kreta' }
    ],
    answer: "Wien"
};



export default function Game() {

    var indepCountries = countries.filter( item => item.independent === true && item.capital !== undefined && item.capital !== null && item.capital !== "" );

    
    const { continent } = useParams();
    const navigate = useNavigate();
    const maxQuestion = 10;
        //load countries
    const [init, setInit] = useState(true);
    const [wrongCount, setWrongCount] = useState(0);
    const [correctCount, setCorrectCount] = useState(maxQuestion);
    const [questionCount, setQuestionCount] = useState(1);
    //const [score, setScore] = useState(0);

    const [done, setDone] = useState(false);


    if (init === true){
        newQuestion();
        setInit(false);
    }

    function scoreSaved() {
        navigate('/');
    }

    function newQuestion() {


        if ( continent !== "Welt" ){
            indepCountries = indepCountries.filter( item => item.region === continent );
        }
        
        function generateWrongAnswers(countryId) {
            const wrongAnswers = [];
            const generateRandomNumbers = (max, times) => {
                const randoms = []

                for (let i = 0; i < times; i++) {
                    let newRand = Math.floor(Math.random() * max);

                    while (newRand === countryId || randoms.includes(newRand)) {
                        newRand = Math.floor(Math.random() * max);
                    }

                    randoms.push(newRand)
                }

                return randoms
            }

            let wrongIds = generateRandomNumbers(indepCountries.length, 3);

            for (const wrongId of wrongIds) {
                wrongAnswers.push({text: indepCountries[wrongId].capital});
            }

            return wrongAnswers;

        }
        let countryId = Math.floor(Math.random() * indepCountries.length);

        dummyQuestion.country = indepCountries[countryId].translations["deu"].common;
        dummyQuestion.answerChoices = generateWrongAnswers(countryId);

        let rndPos= Math.floor(Math.random() * dummyQuestion.answerChoices.length);

        dummyQuestion.answer = rndPos;
        dummyQuestion.answerChoices.splice(rndPos, 0, {text: indepCountries[countryId].capital, correct: true });
    }

    const changeQuestion = (wrong = 0) => {
        //setLifeCount(lifeCount + life);
        //setScore(score + ( bonus * (lifeCount + 1 )));
        setQuestionCount(questionCount + 1);
        setWrongCount(wrongCount + wrong);
        setCorrectCount(correctCount - wrong);

        if ( questionCount === maxQuestion ){
            setDone(true);
        }

        newQuestion();
    }


    //

        //changeQuestion();

        return (

            <>
                {!done && (
                    <div>
                        <HUD questionCount={questionCount} maxQuestion={maxQuestion} />
                        <Question question={dummyQuestion} changeQuestion={changeQuestion}  />
                    </div>
                )}

                {done && (
                   <SaveScoreForm correctCount={correctCount} maxQuestion={maxQuestion} scoreSaved={scoreSaved} />
                )}
            </>


        );
}
