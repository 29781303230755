import React from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <div className="container">
            <h1>Quiz App v2</h1>
            <Link to="/game/Welt" className="btn">
                Welt
            </Link>
            <br/>
            <Link to="/game/Americas" className="btn">
                Amerika
            </Link>
            <Link to="/game/Africa" className="btn">
                Afrika
            </Link>
            <Link to="/game/Asia" className="btn">
                Asien
            </Link>
            <Link to="/game/Europe" className="btn">
                Europa
            </Link>
            <Link to="/game/Oceania" className="btn">
                Ozeanien
            </Link>
        </div>
    );
}
